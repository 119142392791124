import React, { useEffect, useState } from "react";

import CreateProjectCategory from "./CreateProjectCategory";
import { ReactComponent as DeleteIcon } from "../../components/icons/trash_icon.svg";
import { ReactComponent as EditIcon } from "../../components/icons/pencil_icon.svg";
import EditProjectCategory from "./EditProjectCategory";
import Error from "../common/ServerError";
import { ReactComponent as PlusIcon } from "../../components/icons/plus_icon.svg";
import Spinner from "../common/Spinner";
import claims from "../../constants/claims";
import projectCategoryApi from "../../api/projectCategoryApi";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const ProjectCategory = ({ isUserAuthorized }) => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [add, setAdd] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentProjectCategory, setCurrentProjectCategory] = useState(null);
  const [projectCategories, setProjectCategories] = useState([]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await projectCategoryApi
            .list(accessToken)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((e) => {
              console.log(e);
              setError(e);
            })
            .finally(() => setLoading(false));

          setProjectCategories(data);
        })();
      })
      .catch((error) => {
        setError(error);
      });
  }, [instance, accounts]);

  const handleAdd = () => {
    setAdd(true);
    setEditing(false);
  };

  const handleEdit = (projectCategory) => {
    setAdd(false);
    setEditing(true);
    setCurrentProjectCategory(projectCategory);
  };

  const handleUpdate = (updatedProjectCategory) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectCategoryApi
            .put(accessToken, updatedProjectCategory.id, updatedProjectCategory)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((e) => {
              setError(e);
              console.log(e);
            })
            .finally(() => setLoading(false));

          setProjectCategories(
            projectCategories.map((category) =>
              category.id === updatedProjectCategory.id
                ? updatedProjectCategory
                : category
            )
          );
          setEditing(false);
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleCreate = (projectCategory) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectCategoryApi
            .post(accessToken, projectCategory)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              setProjectCategories([...projectCategories, data]);
              return data;
            })
            .catch((e) => {
              console.log(e);
              if (e.response?.status === 400 && e.response?.data?.errors) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  e.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(e);
              }
            })
            .finally(() => setLoading(false));

          setAdd(false);
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleDelete = (projectCategory) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectCategoryApi
            .delete(accessToken, projectCategory.id)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((e) => {
              setError(e);
              console.log(e);
            })
            .finally(() => setLoading(false));

          setProjectCategories(
            projectCategories.filter((p) => p.id !== projectCategory.id)
          );
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="ps-2">
      <div className="row">
        {errorMessages && errorMessages.length > 0 ? (
          <div className="col-12 mt-3">
            <div className="mb-2 text-danger">
              Something went wrong, please try again with correct parameters.
            </div>

            {errorMessages.map((e) => {
              return <div className="mb-2 text-danger">{e.value}</div>;
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <div className="col-12 my-3">
          <h4>Project Categories</h4>
        </div>
        {isUserAuthorized(claims.ProjectsWrite) ? (
          <div className="col-12">
            <button
              type="button"
              className="button"
              onClick={() => handleAdd()}
            >
              <span className="new-icon">
                <PlusIcon />
              </span>
              New
            </button>
          </div>
        ) : null}
      </div>
      <div className="row">
        {editing ? (
          <EditProjectCategory
            editing={editing}
            setEditing={setEditing}
            currentProjectCategory={currentProjectCategory}
            handleUpdate={handleUpdate}
          />
        ) : (
          ""
        )}
      </div>
      <div className="row">
        {add ? (
          <CreateProjectCategory setAdd={setAdd} handleCreate={handleCreate} />
        ) : (
          ""
        )}
      </div>

      <div className="row">
        {projectCategories && projectCategories.length > 0 ? (
          <div className="col-6 my-3">
            <div className="card shadow-sm rounded-extra-lg">
              <div className="card-body">
                <table className="table table-lg table-striped-custom table-borderless table-header-border border-top-none">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectCategories.map((projectCategory) => (
                      <tr key={projectCategory.id}>
                        <td>{projectCategory.name}</td>
                        <td>
                          <div className="flex">
                            <div className="flex flex-end">
                              {isUserAuthorized(claims.ProjectsWrite) ? (
                                <button
                                  className="button extra-small me-2 circle"
                                  onClick={() => handleEdit(projectCategory)}
                                >
                                  <EditIcon />
                                </button>
                              ) : null}

                              {isUserAuthorized(claims.ProjectsDelete) ? (
                                <button
                                  className="button danger extra-small circle"
                                  onClick={() => handleDelete(projectCategory)}
                                >
                                  <DeleteIcon />
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-6 mt-3">No project categories</div>
        )}
      </div>
    </div>
  );
};

export default ProjectCategory;
