import React, { createRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ClearCircleIcon } from "../../components/icons/clear_circle_icon.svg";
import { ReactComponent as ClearIcon } from "../../components/icons/clear_icon.svg";
import DbForecast from "../db-forecast/DbForecast";
import Dropdown from "../common/dropdown/dropdown";
import Error from "../common/ServerError";
import Filter from "../filters/Filter";
import FilterType from "../../constants/filterType";
import Forecast from "../forecast/Forecast";
import Spinner from "../common/Spinner";
import actionType from "../../constants/actionType";
import attributeConfigurationApi from "../../api/attributeConfigApi";
import currencyConstant from "../../constants/currency";
import forecastConfigApi from "../../api/forecastConfigApi";
import forecastViewType from "../../constants/forecastViewType";
import { isTrue } from "../common/utilities";
import { tokenRequest } from "../../authConfig";
import unitOfMeasurements from "../../constants/unitOfMeasurements";
import uomConfigApi from "../../api/uomConfigApi";
import urlHelper from "../common/urlHelper";
import { useMsal } from "@azure/msal-react";

const Search = ({
  showUom,
  showForecast,
  setSearchData,
  checkDataEditLock,
  isWriteOperation,
  searchProjects,
  configurationComponent,
  setCurrentFilterAttributeName,
  parent,
  isUserAuthorized
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getFilterDataFromQueryString, getSearchParams] = urlHelper();

  const [attributes, setattributes] = useState([]);
  const [horizontalAttributes, setHorizontalAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uomData, setUomData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState();
  const { instance, accounts } = useMsal();
  const [currentAction, setCurrentAction] = useState({
    attributeConfiguration: null,
    action: actionType.Read,
  });
  const [config, setConfig] = useState(null);

  const [authorizationError, setAuthorizationError] = useState(null);

  const createUniqueFieldItems = (data) => {
    return [
      ...data
        .reduce((accumulator, currentValue) => {
          const attribute = attributes.find(
            (a) => a.displayName === currentValue.field
          );

          const field = attribute.attributeName.toLowerCase();
          const path = attribute?.parent?.toLowerCase();
          const object = accumulator.get(field);

          if (object) {
            return accumulator.set(field, {
              path: path,
              field: field,
              items: [...new Set(object.items.concat(currentValue.items))],
            });
          } else {
            return accumulator.set(field, {
              path: path,
              field: field,
              items: [...currentValue.items],
            });
          }
        }, new Map())
        .values(),
    ];
  };

  const getSelectedSearchData = (attributeName, searchParams) => {
    const queryStrings = getFilterDataFromQueryString(searchParams);
    let data = [];

    if (queryStrings.length > 0) {
      data = createUniqueFieldItems(queryStrings)
        .filter((item) => item.field !== attributeName)
        .map((unique) => {
          return unique;
        });
    }

    const unitOfMeasurements = getUnitOfMeasurementsValue(searchParams);

    return {
      currency: getCurrency(searchParams, unitOfMeasurements),
      unitOfMeasurements: unitOfMeasurements,
      selectedFilterData: data,
    };
  };

  const [selectedSearchData, setSelectedSearchData] = useState();

  const [appliedFilters, setAppliedFilters] = useState(
    getFilterDataFromQueryString(getSearchParams())
  );

  const [appliedFilterRefs, setAppliedFilterRefs] = useState([]);

  const [currentView, setCurrentView] = useState(forecastViewType.RollingView);

  const handleViewSelection = (value) => {
    setCurrentView(value);
  };

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!config) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    const getAttributeConfigurationApiMethod = () => {
      if (
        searchProjects ||
        (parent === "forecast" &&
          isTrue(config.showProjectFiltersInForecastSearch))
      ) {
        return attributeConfigurationApi.list;
      } else {
        return attributeConfigurationApi.search;
      }
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;

        if (showUom) {
          (async () => {
            await uomConfigApi
              .list(accessToken)
              .then((data) => {
                setError(null);
                setUomData(data.uom);
                setCurrencyData(data.currency);
                setDefaultCurrency(data.defaultCurrency);
              })
              .catch((e) => {
                setError(e);
                console.log(e);
              })
              .finally(() => setLoading(false));
          })();
        }

        (async () => {
          await getAttributeConfigurationApiMethod()(accessToken)
            .then((data) => {
              setError(null);
              const allowedAttributes = data.filter(
                (d) => d.filterType !== FilterType.NONE
              );

              const refs = allowedAttributes.map((attribute) => ({
                displayName: attribute.displayName,
                ref: createRef(null),
              }));

              setAppliedFilterRefs(refs);
              setattributes(allowedAttributes);
              setHorizontalAttributes(
                allowedAttributes.filter(
                  (d) => d.filterType === FilterType.HORIZONTAL
                )
              );
            })
            .catch((e) => {
              setError(e);
              console.log(e);
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setError(error);
      });
  }, [instance, accounts, showUom, searchProjects, config, parent]);

  const showCurrency = (uom) => {
    return uom === unitOfMeasurements.COST || uom === unitOfMeasurements.PRICE;
  };

  const getUnitOfMeasurementsValue = (searchParams) => {
    return (
      (searchParams != null
        ? searchParams.get(unitOfMeasurements.UOM)
        : getSearchParams().get(unitOfMeasurements.UOM)) ||
      unitOfMeasurements.UNITS
    );
  };

  const getUnitOfMeasurements = (value) => {
    const description =
      uomData != null &&
      uomData.length > 0 &&
      uomData.find((f) => f.value === value)?.description;

    return { value: value, description: description ? description : value };
  };

  const getCurrency = (searchParams, uom) => {
    if (showCurrency(uom)) {
      return (
        (searchParams != null
          ? searchParams.get(currencyConstant.CURRENCY)
          : getSearchParams().get(currencyConstant.CURRENCY)) ||
        defaultCurrency ||
        currencyData.find((c) => c.value === 1).description
      );
    } else {
      return currencyConstant.CURRENCY;
    }
  };

  const [selectedUom, setSelectedUom] = useState(
    getUnitOfMeasurements(getUnitOfMeasurementsValue())
  );
  const [currencyText, setCurrencyText] = useState(currencyConstant.CURRENCY);
  const [enableCurrency, setEnableCurrency] = useState(false);

  useEffect(() => {
    if (uomData == null || uomData.length === 0) {
      return;
    }

    setSelectedUom(getUnitOfMeasurements(getUnitOfMeasurementsValue()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomData]);

  useEffect(() => {
    if (currencyData == null || currencyData.length === 0) {
      return;
    }
    setCurrencyText(getCurrency(null, selectedUom.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyData, selectedUom]);

  useEffect(() => {
    setEnableCurrency(showCurrency(selectedUom.value));
  }, [selectedUom]);

  useEffect(() => {
    if (
      attributes == null ||
      attributes.length === 0 ||
      (showUom &&
        (uomData == null ||
          uomData.length === 0 ||
          currencyData == null ||
          currencyData.length === 0))
    ) {
      return;
    }
    setSelectedSearchData(getSelectedSearchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes, uomData, currencyData, showUom]);

  useEffect(() => {
    if (setSearchData == null) {
      return;
    }
    setSearchData(selectedSearchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchData]);

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await forecastConfigApi
            .list(accessToken)
            .then((data) => {
              setError(null);
              if (data) {
                const forecast = data.attributeConfigurations
                  .filter((f) => f.parent.toLowerCase() === "forecast")
                  .map((f) => f.attributeName)
                  .join(",");
                const history = data.attributeConfigurations
                  .filter((f) => f.parent.toLowerCase() === "history")
                  .map((f) => f.attributeName)
                  .join(",");
                const projects = data.attributeConfigurations
                  .filter((f) => f.parent.toLowerCase() === "projects")
                  .map((f) => f.attributeName)
                  .join(",");

                const fields = [forecast, history, projects].join("|");
                setConfig({ ...data, fields });
              }
            })
            .catch((e) => {
              setError(e);
              console.log(e);
            });
        })();
      })
      .catch((error) => {
        setError(error);
      });
  }, [instance, accounts]);

  const updateSearch = (searchParams, currentFilterAttributeName) => {
    setAppliedFilters(getFilterDataFromQueryString(searchParams));
    const searchData = getSelectedSearchData(null, searchParams);
    setSelectedUom(getUnitOfMeasurements(searchData.unitOfMeasurements));
    setCurrencyText(searchData.currency);

    if (JSON.stringify(selectedSearchData) !== JSON.stringify(searchData)) {
      setSelectedSearchData(searchData);
    }
    if (setCurrentFilterAttributeName && currentFilterAttributeName) {
      setCurrentFilterAttributeName(currentFilterAttributeName);
    }
  };

  const handleRemoveFilterItem = (item, displayName) => {
    const filterRef = appliedFilterRefs.find(
      (r) => r.displayName === displayName
    );
    filterRef.ref.current(item);
  };

  const handleClearAllFilters = () => {
    const params = new URLSearchParams(location.search);

    Array.from(params.keys()).forEach((param) => {
      params.delete(param);
    });

    navigate(`${location.pathname}?${params}`, { replace: true });

    setAppliedFilters([]);
    setSelectedUom(getUnitOfMeasurements(getUnitOfMeasurementsValue(params)));
    setSelectedSearchData({
      ...getSelectedSearchData(null, params),
      clearAll: true,
    });
  };

  const handleUomDropdownSelection = (item) => {
    const params = getSearchParams();
    params.delete(unitOfMeasurements.UOM);
    params.append(unitOfMeasurements.UOM, item.value);
    navigate(`${location.pathname}?${params}`, { replace: true });
    updateSearch(params);
  };

  const handleCurrencyDropdownSelection = (item) => {
    const params = getSearchParams();
    params.delete(currencyConstant.CURRENCY);
    params.append(currencyConstant.CURRENCY, item.description);
    navigate(`${location.pathname}?${params}`, { replace: true });
    updateSearch(params);
  };

  const handleEditParam = (append) => {
    const params = getSearchParams();
    params.delete(actionType.Edit);
    if (append) {
      params.append(actionType.Edit, true);
    }
    navigate(`${location.pathname}?${params}`, { replace: true });
  };

  const handleEdit = (attributeConfiguration, action, areaCode, item) => {
    handleEditParam(true);
    setCurrentAction({
      attributeConfiguration,
      action,
      areaCode,
      item,
    });
  };

  const handleBack = () => {
    handleEditParam(false);
    setCurrentAction({ attributeConfiguration: null, action: actionType.Read });
  };

  const handleSearchCallback = () => {
    handleEditParam(false);
  };

  const showBudgetView = () => {
    return (
      config &&
      config.budgetViewButtonText &&
      config.budgetViewForecastFromDate &&
      config.budgetViewForecastToDate
    );
  };

  const showFutureView = () => {
    return (
      config &&
      config.futureViewButtonText &&
      config.futureViewForecastFromDate &&
      config.futureViewForecastToDate
    );
  };

  const showViewBar = () => {
    return (
      config &&
      config.rollingViewButtonText &&
      (showBudgetView() || showFutureView())
    );
  };

  const getFiltersTemplate = () => {
    const filters = horizontalAttributes.map((attribute) => {
      const { parent, attributeName, displayName } = attribute;
      const ref = appliedFilterRefs.find(
        (r) => r.displayName === displayName
      ).ref;
      return (
        <Filter
          updateSearch={updateSearch}
          getSelectedSearchData={getSelectedSearchData}
          displayName={displayName}
          path={parent}
          attributeName={attributeName}
          key={attributeName}
          onRef={(callback) => {
            ref.current = callback;
          }}
          isWriteOperation={isWriteOperation}
        />
      );
    });

    return filters;
  };

  const getAppliedFiltersTemplate = () => {
    const filters = appliedFilters.map((filters) => {
      return (
        <React.Fragment key={filters.field}>
          {filters.items.map((item) => {
            return (
              <div
                className="item"
                onClick={(e) => handleRemoveFilterItem(item, filters.field)}
                key={item}
              >
                <span className="name">{filters.field}: </span>
                <span>{item}</span>
                <span className="clear">
                  <ClearIcon />
                </span>
              </div>
            );
          })}
        </React.Fragment>
      );
    });

    return (
      <div className="filter-summary" key="appliedfilters">
        {filters}
      </div>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (authorizationError) {
    return <Error error={authorizationError} />;
  }

  return (
    <React.Fragment>
      {appliedFilters.length > 0 ? (
        <div className="row mt-2">
          <div className="col-12">
            <div
              className={`applied-filters${
                currentAction.action === actionType.Read ? "" : " disabled"
              }`}
            >
              {getAppliedFiltersTemplate()}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {currentAction.action === actionType.Read ? (
        <div className="row filter-bar">
          <div className="col-12">
            <div className="flex">
              <div className="flex">
                {getFiltersTemplate()}
                <button
                  className="link clear"
                  disabled={
                    appliedFilters.length === 0 &&
                    selectedUom.value === unitOfMeasurements.UNITS
                  }
                  onClick={handleClearAllFilters}
                >
                  <span className="me-1">
                    <ClearCircleIcon />
                  </span>
                  <span>Clear all</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <React.Fragment>
        {currentAction.action === actionType.Read ? (
          showForecast || configurationComponent ? (
            <div className="row">
              <div className="col-12 me-2">
                <div className="search-view-container my-3">
                  {configurationComponent ? (
                    <div className="configuration">
                      {configurationComponent}
                    </div>
                  ) : null}

                  {showForecast && showViewBar() ? (
                    <React.Fragment>
                      <div className="flex-2"></div>
                      <div className="switch-container">
                        <fieldset className="switch-fieldset">
                          <div className="switch-group">
                            <label className="switch-wrap">
                              <input
                                type="radio"
                                name="rollingview"
                                key="rollingview"
                                checked={
                                  currentView === forecastViewType.RollingView
                                }
                                onChange={(e) =>
                                  handleViewSelection(
                                    forecastViewType.RollingView
                                  )
                                }
                              />
                              <div className="switch">
                                {config.rollingViewButtonText}
                              </div>
                            </label>
                            {showBudgetView() ? (
                              <label className="switch-wrap">
                                <input
                                  type="radio"
                                  name="budgetview"
                                  key="budgetview"
                                  checked={
                                    currentView === forecastViewType.BudgetView
                                  }
                                  onChange={(e) =>
                                    handleViewSelection(
                                      forecastViewType.BudgetView
                                    )
                                  }
                                />
                                <div className="switch">
                                  {config.budgetViewButtonText}
                                </div>
                              </label>
                            ) : null}

                            {showFutureView() ? (
                              <label className="switch-wrap">
                                <input
                                  type="radio"
                                  name="futureview"
                                  key="futureview"
                                  checked={
                                    currentView === forecastViewType.FutureView
                                  }
                                  onChange={(e) =>
                                    handleViewSelection(
                                      forecastViewType.FutureView
                                    )
                                  }
                                />
                                <div className="switch">
                                  {config.futureViewButtonText}
                                </div>
                              </label>
                            ) : null}
                          </div>
                        </fieldset>
                      </div>
                    </React.Fragment>
                  ) : null}

                  {showUom && uomData.length > 0 ? (
                    <div className="uom">
                      <Dropdown
                        disabled={false}
                        data={uomData}
                        buttonText={selectedUom.description}
                        header={unitOfMeasurements.UOM}
                        handleDropdownSelection={handleUomDropdownSelection}
                      />
                      {currencyData.length > 0 ? (
                        <Dropdown
                          disabled={!enableCurrency}
                          data={currencyData}
                          buttonText={currencyText}
                          header={currencyConstant.CURRENCY}
                          handleDropdownSelection={
                            handleCurrencyDropdownSelection
                          }
                          scrollbar={true}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              {showForecast ? (
                <Forecast
                  config={config}
                  selectedSearchData={selectedSearchData}
                  key="forecast"
                  handleEdit={handleEdit}
                  currentView={currentView}
                  setAuthorizationError={setAuthorizationError}
                  isUserAuthorized={isUserAuthorized}
                />
              ) : null}
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="row pt-3">
                <div className="col-12 flex">
                  <div className="flex ps-2">
                    <h4>Edit Forecast</h4>
                  </div>
                </div>
                <DbForecast
                  config={config}
                  selectedSearchData={selectedSearchData}
                  key="forecast"
                  currentAction={currentAction}
                  setCurrentAction={setCurrentAction}
                  handleSearchCallback={handleSearchCallback}
                  checkDataEditLock={checkDataEditLock}
                  setAuthorizationError={setAuthorizationError}
                  handleBack={handleBack}
                  isUserAuthorized={isUserAuthorized}
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default Search;
