/* eslint-disable jsx-a11y/anchor-is-valid */

import "bootstrap/dist/js/bootstrap.js";
import "jquery";
import "popper.js";
import "./app.scss";

import { NavLink, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow_icon.svg";
import Configuration from "../configuration/Configuration";
import DataExport from "../data-integration/DataExport";
import DataIntegrationStatus from "../data-integration/DataIntegrationStatus";
import Forbidden from "../common/Forbidden";
import ForecastAuditSearch from "../forecast/ForecastAuditSearch";
import ForecastOverrideListing from "../forecast-override/OverrideListing";
import Home from "./Home/Home";
import Logo from "./small-logo-white.png";
import NotFound from "../common/NotFound";
import OverrideFileUpload from "../forecast-override/FileUpload";
import PrivateRoute from "../common/PrivateRoute";
import ProcessProject from "../project/ProcessProject";
import Project from "../project/Project";
import ProjectCategory from "../project-category/ProjectCategory";
import ProjectSearch from "../project/ProjectSearch";
import RunDataIntegration from "../data-integration/RunDataIntegration";
import Search from "../search/Search";
import SearchDataRefresh from "../data-integration/SearchDataRefresh";
import ServerError from "../common/ServerError";
import SignInView from "../identity/SignInView";
import authorizationApi from "../../api/authorizationApi";
import authorizationHelper from "../common/authorizationHelper";
import claims from "../../constants/claims";
import dataIntegrationName from "../../constants/dataIntegrationName";
import { isTrue } from "../common/utilities";
import status from "../../constants/status";
import { tokenRequest } from "../../authConfig";
import useDataIntegration from "../data-integration/useDataIntegration";
import { useLocation } from "react-router-dom";

function App() {
  const { instance, accounts } = useMsal();
  const [userClaims, setUserClaims] = useState([]);
  const [userClaimsLoading, setUserClaimsLoading] = useState(true);
  const [, isAuthorized] = authorizationHelper();
  const isAuthenticated = useIsAuthenticated();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const { dataIntegrationLog, errorWhileCheckingDataIntegration } =
    useDataIntegration();
  const location = useLocation();
  const showStatus = () => {
    if (location && location.pathname) {
      if (location.pathname === "/search-data-refresh") {
        return false;
      }

      if (
        location.pathname === "/data-import" &&
        dataIntegrationLog &&
        dataIntegrationLog.name === dataIntegrationName.DataImport &&
        dataIntegrationLog.status === status.Failed
      ) {
        return false;
      }

      if (
        location.pathname === "/data-export" &&
        dataIntegrationLog &&
        dataIntegrationLog.name === dataIntegrationName.DataExport &&
        dataIntegrationLog.status === status.Failed
      ) {
        return false;
      }
    }

    return dataIntegrationLog && dataIntegrationLog.status !== status.Completed;
  };

  const checkDataEditLock = (enabletEditLocking, setErrorMessages) => {
    if (
      isTrue(enabletEditLocking) &&
      dataIntegrationLog &&
      dataIntegrationLog.name === dataIntegrationName.DataExport &&
      dataIntegrationLog.status === status.Completed
    ) {
      setErrorMessages([
        {
          name: "",
          value:
            "Data editing is LOCKED. Data has not been imported after the last Data Export. Please run the Data Import or contact support to disable the lock.",
        },
      ]);

      return true;
    }

    return null;
  };

  const isUserAuthorized = (claimToBeAuthorized) => {
    return isAuthorized(userClaims, claimToBeAuthorized);
  };

  const checkRouteAuthorization = (claimToBeAuthorized) => {
    return !isAuthenticated || isAuthorized(userClaims, claimToBeAuthorized);
  };

  const getAuthorizedExportPipelines = () => {
    if (!isAuthorized(userClaims, claims.RunDataExportPipeline)) {
      return null;
    }
    if (userClaims && userClaims.claimsWithValues) {
      const exports = userClaims.claimsWithValues.find(
        (c) => c.key === claims.DataExportPipelines
      )?.values;

      return exports;
    }

    return null;
  };

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setUserClaimsLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await authorizationApi
            .getClaims(accessToken)
            .then((data) => {
              setUserClaims(data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setUserClaimsLoading(false);
            });
        })();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance, accounts]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow-sm">
        <NavLink className="navbar-brand d-inline-block" to="/">
          <img src={Logo} alt="" width="50" />
          <span className="font-weight-medium ms-1">
            Brookes Revenue Planner
          </span>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="ms-2 collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <NavLink className="nav-link me-2" end to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item dropdown me-2">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Forecast
                <span className="ms-2 arrow-icon">
                  <ArrowIcon />
                </span>
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <NavLink className="dropdown-item" to="/search">
                  Search
                </NavLink>
                {isUserAuthorized(claims.ForecastOverrideFileUploadWrite) ? (
                  <NavLink
                    className="dropdown-item"
                    to="/forecast/upload-overrides"
                  >
                    Upload Overrides
                  </NavLink>
                ) : null}
              </div>
            </li>

            <li className="nav-item dropdown me-2">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Projects
                <span className="ms-2 arrow-icon">
                  <ArrowIcon />
                </span>
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <NavLink className="dropdown-item" to="/projects">
                  Projects
                </NavLink>
                <NavLink className="dropdown-item" to="/projects/categories">
                  Categories
                </NavLink>
                {/* {isUserAuthorized(claims.DataIntegrationWrite) ? (
                    <NavLink className="dropdown-item" to="/projects/process">
                      Process
                    </NavLink>
                  ) : null} */}
              </div>
            </li>

            {isUserAuthorized(claims.RunDataImportPipeline) ||
            isUserAuthorized(claims.RunDataExportPipeline) ? (
              <li className="nav-item dropdown me-2">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Data Integration
                  <span className="ms-2 arrow-icon">
                    <ArrowIcon />
                  </span>
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {isUserAuthorized(claims.RunDataImportPipeline) ? (
                    <NavLink className="dropdown-item" to="/data-import">
                      Data Import
                    </NavLink>
                  ) : null}

                  {isUserAuthorized(claims.RunDataExportPipeline) ? (
                    <NavLink className="dropdown-item" to="/data-export">
                      Data Export
                    </NavLink>
                  ) : null}

                  {/* {isUserAuthorized(claims.DataIntegrationWrite) ? (
                    <NavLink
                      className="dropdown-item"
                      to="/search-data-refresh"
                    >
                      Search Data Refresh
                    </NavLink>
                  ) : null} */}
                </div>
              </li>
            ) : null}
          </ul>
          <SignInView />
        </div>
      </nav>
      <div className="container-fluid">
        {showStatus() ? (
          <React.Fragment>
            <DataIntegrationStatus
              dataIntegrationLog={dataIntegrationLog}
              errorWhileCheckingDataIntegration={
                errorWhileCheckingDataIntegration
              }
            />
          </React.Fragment>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            {checkRouteAuthorization(claims.SearchRead) ? (
              <Route
                path="/search"
                element={
                  <PrivateRoute>
                    <Search
                      showUom={true}
                      showForecast={true}
                      checkDataEditLock={checkDataEditLock}
                      parent={"forecast"}
                      isUserAuthorized={isUserAuthorized}
                    />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route path="/search" element={<Forbidden></Forbidden>} />
            ) : null}

            {checkRouteAuthorization(claims.ForecastOverrideFileUploadWrite) ? (
              <Route
                path="/forecast/upload-overrides"
                element={
                  <PrivateRoute>
                    <OverrideFileUpload
                      checkDataEditLock={checkDataEditLock}
                      isUserAuthorized={isUserAuthorized}
                    />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/forecast/upload-overrides"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.ForecastOverrideLogs) ? (
              <Route
                path="/forecast/override-logs"
                element={
                  <PrivateRoute>
                    <ForecastOverrideListing />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/forecast/override-logs"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.ForecastOverrideAuditLogs) ? (
              <Route
                path="/forecast/audit-logs"
                element={
                  <PrivateRoute>
                    <ForecastAuditSearch />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/forecast/audit-logs"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.ProjectsRead) ? (
              <Route
                path="/projects/:projectName"
                element={
                  <PrivateRoute>
                    <Project
                      checkDataEditLock={checkDataEditLock}
                      isUserAuthorized={isUserAuthorized}
                    />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/projects/:projectName"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.ProjectsWrite) ? (
              <Route
                path="/projects/edit/:projectName"
                element={
                  <PrivateRoute>
                    <Project
                      checkDataEditLock={checkDataEditLock}
                      isUserAuthorized={isUserAuthorized}
                    />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/projects/edit/:projectName"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.ProjectsRead) ? (
              <Route
                path="/projects"
                element={
                  <PrivateRoute>
                    <Project
                      checkDataEditLock={checkDataEditLock}
                      isUserAuthorized={isUserAuthorized}
                    />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route path="/projects" element={<Forbidden></Forbidden>} />
            ) : null}

            {checkRouteAuthorization(claims.ProjectsRead) ? (
              <Route
                path="/projects/categories"
                element={
                  <PrivateRoute>
                    <ProjectCategory isUserAuthorized={isUserAuthorized} />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/projects/categories"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.ProjectsRead) ? (
              <Route
                path="/projects/search"
                element={
                  <PrivateRoute>
                    <ProjectSearch />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/projects/search"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.Configuration) ? (
              <Route
                path="/configuration"
                element={
                  <PrivateRoute>
                    <Configuration />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route path="/configuration" element={<Forbidden></Forbidden>} />
            ) : null}

            {checkRouteAuthorization(claims.DataIntegrationWrite) ? (
              <Route
                path="/search-data-refresh"
                element={
                  <PrivateRoute>
                    <SearchDataRefresh />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/search-data-refresh"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            {checkRouteAuthorization(claims.RunDataImportPipeline) ? (
              <Route
                path="/data-import"
                element={
                  <PrivateRoute>
                    <RunDataIntegration
                      key={dataIntegrationName.DataImport}
                      name={dataIntegrationName.DataImport}
                    />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route path="/data-import" element={<Forbidden></Forbidden>} />
            ) : null}

            {checkRouteAuthorization(claims.RunDataExportPipeline) ? (
              <Route
                path="/data-export"
                element={
                  <PrivateRoute>
                    <DataExport pipelines={getAuthorizedExportPipelines()} />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route path="/data-export" element={<Forbidden></Forbidden>} />
            ) : null}

            {checkRouteAuthorization(claims.DataIntegrationWrite) ? (
              <Route
                path="/projects/process"
                element={
                  <PrivateRoute>
                    <ProcessProject />
                  </PrivateRoute>
                }
              />
            ) : !userClaimsLoading ? (
              <Route
                path="/projects/process"
                element={<Forbidden></Forbidden>}
              />
            ) : null}

            <Route
              path="/server-error"
              element={
                <PrivateRoute>
                  <ServerError />
                </PrivateRoute>
              }
            />
            <Route
              path="/forbidden"
              element={
                <PrivateRoute>
                  <Forbidden />
                </PrivateRoute>
              }
            />
            <Route
              element={
                <PrivateRoute>
                  <NotFound />
                </PrivateRoute>
              }
            />
          </Routes>
        )}
      </div>
      <footer className="footer">
        <div className="container-fluid pt-3">
          <p className="text-center">
            Planning 365 © {getCurrentYear()} Brookes
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
