import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as BackIcon } from "../../components/icons/arrow_icon.svg";
import { ReactComponent as ClearCircleIcon } from "../../components/icons/clear_circle_icon.svg";
import CreateProject from "./CreateProject";
import { ReactComponent as DeleteIcon } from "../../components/icons/trash_icon.svg";
import { ReactComponent as DetailIcon } from "../../components/icons/details_icon.svg";
import { ReactComponent as EditIcon } from "../../components/icons/pencil_icon.svg";
import EditProject from "./EditProject";
import Error from "../common/ServerError";
import { ReactComponent as FileUploadIcon } from "../../components/icons/fileupload_icon.svg";
import { ReactComponent as HelpIcon } from "../../components/icons/help_circle_icon.svg";
import ItemArea from "./ItemArea";
import Modal from "../common/modal/modal";
import { ReactComponent as PlusIcon } from "../../components/icons/plus_icon.svg";
import ProjectCard from "./ProjectCard";
import ProjectDetail from "./ProjectDetail";
import Search from "../../components/search/Search";
import { ReactComponent as SearchIcon } from "../../components/icons/search_icon.svg";
import Spinner from "../common/Spinner";
import actionType from "../../constants/actionType";
import authorizationHelper from "../common/authorizationHelper";
import claims from "../../constants/claims";
import hierarchy from "../../constants/hierarchy";
import projectApi from "../../api/projectApi";
import projectSteps from "../../constants/projectSteps";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import viewType from "../../constants/viewType";

const Project = ({ checkDataEditLock, isUserAuthorized }) => {
  const projectsPathname = "/projects";
  const { projectName } = useParams();

  const [isDataAdmin] = authorizationHelper();

  const location = useLocation();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [error, setError] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [projects, setProjects] = useState({ results: [] });
  const [configuration, setConfiguration] = useState();

  const [searchData, setSearchData] = useState(null);
  const [selectedItemAreaData, setSelectedItemAreaData] = useState({});
  const view = viewType.ListView;

  const [currentState, setCurrentState] = useState({
    currentStep: null,
    currentProject: null,
    fileUploadMessage: null,
  });

  const initialRequest = {
    pageSize: 20,
    pageNumber: 1,
    refresh: true,
    searchQuery: "",
    isResponseUpdate: false,
  };

  const [request, setRequest] = useState(initialRequest);

  const [showModal, setShowModal] = useState(false);

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const [isFileUpload, setIsFileUpload] = useState(false);

  const maxTimeHorizonInMonths = 12;

  const [showHelp, setShowHelp] = useState(false);

  const [showAuthorizationErrorModel, setShowAuthorizationErrorModel] =
    useState(false);

  const [authorizationError, setAuthorizationError] = useState(null);

  const [fileUploadColumnValueNames, setFileUploadColumnValueNames] = useState(
    []
  );

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectApi
            .configuration(accessToken)
            .then((data) => {
              setConfiguration(data);
            })
            .catch((error) => {
              console.log(error);
            });
        })();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance, accounts]);

  useEffect(() => {
    if (
      !instance ||
      !accounts ||
      accounts.length === 0 ||
      request.isResponseUpdate
    ) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await projectApi
            .list(accessToken, request)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() => setLoading(false));

          if (request.refresh) {
            setProjects(data);
          } else {
            setProjects((project) => {
              return {
                ...data,
                results: [...project.results, ...data.results],
              };
            });
          }

          setRequest((prev) => {
            return {
              ...prev,
              pageSize: data.pageSize,
              pageNumber: data.currentPage,
              isResponseUpdate: true,
            };
          });
        })();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, [instance, accounts, request]);

  useEffect(() => {
    if (!configuration) {
      return;
    }

    const attributeDisplayNames = configuration.attributeConfigurations.map(
      (a) => a.displayName
    );

    const authorizedAttributeConfiguration =
      configuration.attributeConfigurations.filter((c) =>
        hasWritePermissions(c.attributeName)
      );

    setFileUploadColumnValueNames(authorizedAttributeConfiguration);

    if (
      (currentState.currentStep === projectSteps.EditProject ||
        currentState.currentStep === projectSteps.CreateProject) &&
      !authorizedAttributeConfiguration.length
    ) {
      setError({
        response: {
          status: 403,
          data: {
            message: `You don't have write permission to create/modify ${attributeDisplayNames.join(
              ", "
            )} time horizon values.`,
          },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration, currentState]);

  const handleAdd = () => {
    setCurrentState({
      ...currentState,
      currentStep: projectSteps.CreateProject,
    });
  };

  const handleAddFileUpload = () => {
    setIsFileUpload(true);
    setCurrentState({
      ...currentState,
      currentStep: projectSteps.CreateProject,
      isFileUpload: true,
    });
  };

  const handleAddItemArea = (project) => {
    setCurrentState({
      currentProject: project,
      currentStep: projectSteps.CreateProjectItemArea,
    });
  };

  const handleEditItemArea = (updatedProject) => {
    setCurrentState({
      currentProject: updatedProject,
      currentStep: projectSteps.EditProjectItemArea,
    });
  };

  const authorize = (project, isWriteOperation, callbackAction) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await projectApi
            .authorize(accessToken, project.id, isWriteOperation)
            .then((data) => {
              setAuthorizationError(null);
              return data;
            })
            .catch((e) => {
              handleShowAuthorizationErrorModel(e);
              console.log(e);
            });

          if (data) {
            callbackAction(project);
          }
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getProjectByName = (name, isWriteOperation, callbackAction) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await projectApi
            .getByName(accessToken, name, isWriteOperation)
            .then((data) => {
              setAuthorizationError(null);
              return data;
            })
            .catch((e) => {
              handleShowAuthorizationErrorModel(e);
              console.log(e);
            });

          if (data) {
            callbackAction(data);
          }
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleDetailProjectCallback = (project) => {
    setCurrentState({
      currentProject: project,
      currentStep: projectSteps.Details,
    });
    if (project) {
      navigate(`${projectsPathname}/${project.name}`, {
        replace: true,
      });
    }
  };

  const handleDetailProject = (project) => {
    authorize(project, false, handleDetailProjectCallback);
  };

  const handleEditProjectCallback = (project) => {
    setCurrentState({
      currentProject: project,
      currentStep: projectSteps.EditProject,
    });

    if (project) {
      if (
        project.projectItemAreaSelection &&
        project.projectItemAreaSelection.additionalInfo
      ) {
        navigate(
          `${projectsPathname}/edit/${project.name}${project?.projectItemAreaSelection?.additionalInfo}`,
          { replace: true }
        );
      } else {
        navigate(`${projectsPathname}/edit/${project.name}`, {
          replace: true,
        });
      }
    }
  };

  const handleEditProject = (project) => {
    authorize(project, true, handleEditProjectCallback);
  };

  useEffect(() => {
    if (
      projectName &&
      projects &&
      projects.results &&
      projects.results.length > 0
    ) {
      const thisProject = projects?.results?.find(
        (p) => p.name === projectName
      );

      if (location.pathname.indexOf("edit") === -1) {
        if (!thisProject) {
          getProjectByName(projectName, false, handleDetailProjectCallback);
        } else {
          handleDetailProject(thisProject);
        }
      } else {
        if (!thisProject) {
          getProjectByName(projectName, true, handleEditProjectCallback);
        } else {
          handleEditProject(thisProject);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, projects]);

  const handleCancel = () => {
    setIsFileUpload(false);
    setCurrentState({
      currentProject: null,
      currentStep: null,
      isFileUpload: false,
    });

    setErrorMessages([]);
    navigate(`${projectsPathname}`, { replace: true });
    setSearchData(null);
    resetSelectedItemAreaData();
    setRequest(initialRequest);
  };

  const handleBack = () => {
    setCurrentState({
      ...currentState,
      currentStep: projectSteps.EditProject,
    });
    setSearchData(null);
    resetSelectedItemAreaData();
    setIsFileUpload(false);
  };

  const resetSelectedItemAreaData = () => {
    setSelectedItemAreaData({
      selectAll: false,
      itemAreaSelections: [],
    });
  };

  const disableSaveButton = () => {
    const anyItemAreasSelected =
      selectedItemAreaData?.itemAreaSelections?.filter((ia) => ia.isSelected);
    return (
      authorizationError ||
      (!selectedItemAreaData?.selectAll && !anyItemAreasSelected?.length > 0)
    );
  };

  const handleSave = () => {
    const lockEdit = checkDataEditLock(
      configuration.enableEditLock,
      setErrorMessages
    );

    if (lockEdit) {
      return;
    }

    const filters =
      searchData != null && searchData.selectedFilterData != null
        ? searchData.selectedFilterData.map((filterData) => {
            return {
              parent: filterData.path,
              field: filterData.field,
              items: filterData.items,
            };
          })
        : null;

    let itemAreaSelections =
      selectedItemAreaData?.itemAreaSelections?.length > 0
        ? selectedItemAreaData.itemAreaSelections.map((itemArea) => {
            return {
              itemCode: itemArea.itemCode,
              areaCode: itemArea.areaCode,
              isSelected: itemArea.isSelected,
            };
          })
        : [];

    if (itemAreaSelections.length) {
      itemAreaSelections = selectedItemAreaData.selectAll
        ? itemAreaSelections.filter((ia) => !ia.isSelected)
        : itemAreaSelections.filter((ia) => ia.isSelected);
    }

    const projectItemAreaSelection = {
      ...currentState.currentProject.projectItemAreaSelection,
      projectId: currentState.currentProject.id,
      filters: filters,
      itemAreaSelectionContainer: {
        selectAll: selectedItemAreaData.selectAll,
        itemAreaSelections: itemAreaSelections,
      },
      additionalInfo: location.search,
    };

    const saveRequest = {
      ...currentState.currentProject,
      validFrom: new Date(
        Date.UTC(
          currentState.currentProject.validFrom.getFullYear(),
          currentState.currentProject.validFrom.getMonth(),
          1
        )
      ),
      validTo: new Date(
        Date.UTC(
          currentState.currentProject.validTo.getFullYear(),
          currentState.currentProject.validTo.getMonth(),
          1
        )
      ),
      projectItemAreaSelection: projectItemAreaSelection,
    };

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    setLoading(true);

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectApi
            .post(accessToken, saveRequest, saveRequest.id)
            .then((data) => {
              setError(null);
              setAuthorizationError(null);
              setCurrentState({
                currentProject: data,
                currentStep: projectSteps.ListView,
              });
              setErrorMessages([]);
              navigate(`${projectsPathname}`, { replace: true });
              setSearchData(null);
              resetSelectedItemAreaData();
              setRequest(initialRequest);
              return data;
            })
            .catch((error) => {
              console.log(error);
              if (
                error.response?.status === 400 &&
                error.response?.data?.errors
              ) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(error);
                setAuthorizationError(error);
                setCurrentState({
                  ...currentState,
                  currentStep: projectSteps.Error,
                });
              }
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const checkProjectExists = (name, id) => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!name) {
      setError(null);
      setErrorMessages([]);
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectApi
            .validateProjectName(accessToken, name, id)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              return data;
            })
            .catch((error) => {
              console.log(error);
              if (
                error.response?.status === 400 &&
                error.response?.data?.errors
              ) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(error);
                setCurrentState({
                  ...currentState,
                  currentStep: projectSteps.Error,
                });
              }
            })
            .finally();
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const [timeOutId, setTimeOutId] = useState("");
  const [searchTimeOutId, setSearchTimeOutId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const validateProjectName = (value, projectId) => {
    clearTimeout(timeOutId);
    const id = setTimeout(() => {
      checkProjectExists(value, projectId);
    }, 500);
    setTimeOutId(id);
  };

  const handleProjectSearch = (value) => {
    clearTimeout(searchTimeOutId);
    setSearchQuery(value);

    const id = setTimeout(() => {
      setRequest((prev) => {
        return {
          ...prev,
          ...initialRequest,
          searchQuery: value,
          refresh: true,
        };
      });
    }, 500);
    setSearchTimeOutId(id);
  };

  const handleFileUpload = (project, file, setFileUploadingStatus) => {
    const lockEdit = checkDataEditLock(
      configuration.enableEditLock,
      setErrorMessages
    );

    if (lockEdit) {
      return;
    }

    setFileUploadingStatus({
      isUploading: true,
    });

    const formData = new FormData();

    formData.append("formFile", file);
    formData.append("fileName", file.name);
    formData.append("hierarchy", hierarchy[0].value);

    if (project.id) {
      formData.append("id", project.id);
    }

    formData.append("name", project.name);

    if (project.workflowStatus) {
      formData.append("workflowStatus", project.workflowStatus);
    }

    formData.append("description", project.description);
    formData.append("projectCategoryId", project.projectCategoryId);
    formData.append("uoM", project.uoM);
    formData.append("currency", project.currency);

    const fromDate = new Date(
      Date.UTC(project.validFrom.getFullYear(), project.validFrom.getMonth(), 1)
    );

    const toDate = new Date(
      Date.UTC(project.validTo.getFullYear(), project.validTo.getMonth(), 1)
    );

    formData.append("validFrom", fromDate.toUTCString());
    formData.append("validTo", toDate.toUTCString());

    if (
      project.projectAttributeDetails &&
      project.projectAttributeDetails.length > 0
    ) {
      formData.append(
        "projectAttributeDetailsString",
        JSON.stringify(project.projectAttributeDetails)
      );
    }

    formData.append("timeHorizonColumnName", project.timeHorizonColumnName);

    const saveRequest = {
      ...project,
      projectItemAreaSelection: null,
    };

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectApi
            .fileUpload(accessToken, formData, saveRequest.id)
            .then((data) => {
              setError(null);
              setFileUploadingStatus({
                response: data,
              });

              setErrorMessages([]);
              return data;
            })
            .catch((error) => {
              console.log(error);
              setFileUploadingStatus({
                isUploading: false,
                response: null,
              });
              if (
                error.response?.status === 400 &&
                error.response?.data?.errors
              ) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(error);
                setCurrentState({
                  ...currentState,
                  currentStep: projectSteps.Error,
                });
              }
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const handleNext = () => {
    setRequest((prev) => {
      return {
        ...prev,
        refresh: false,
        pageSize: request.pageSize,
        pageNumber: request.pageNumber + 1,
        isResponseUpdate: false,
      };
    });
  };

  const handleDeleteCallback = (project) => {
    setShowModal(true);
    setCurrentState({
      ...currentState,
      currentProject: project,
    });
  };

  const handleDelete = (project) => {
    authorize(project, true, handleDeleteCallback);
  };

  const handleDeleteConfirm = (project) => {
    if (!project) {
      return;
    }

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    setIsDeleteInProgress(true);

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await projectApi
            .delete(accessToken, project.id)
            .then((data) => {
              setError(null);
              setShowModal(false);
              setRequest(initialRequest);
              setIsDeleteInProgress(false);
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
              setIsDeleteInProgress(false);
            });
        })();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const hasWritePermissions = (attributeName) => {
    if (!isUserAuthorized(claims.ProjectsWrite)) {
      return false;
    }

    if (!configuration) {
      return false;
    }

    if (isDataAdmin(configuration.roles)) {
      return true;
    }

    if (!attributeName && configuration.editableRevisions) {
      return true;
    }

    if (
      configuration.editableRevisions &&
      configuration.editableRevisions.includes(attributeName)
    ) {
      return true;
    }

    return false;
  };

  const hasDeletePermissions = () => {
    return isUserAuthorized(claims.ProjectsDelete);
  };

  const createProjectsTable = () => {
    const rows = projects.results.map((project) => {
      return (
        <tr key={project.id}>
          {/* <th scope="row">{project.id}</th> */}
          <td>{project.name}</td>
          {/* <td>{project.status}</td> */}
          <td>{project.category.name}</td>
          <td>{project.uoM}</td>
          <td>{project.currency}</td>
          <td>{project.validFrom}</td>
          <td>{project.validTo}</td>
          <td className="action-container">
            <button
              className="button extra-small me-2 circle detail"
              onClick={() => handleDetailProject(project)}
            >
              <DetailIcon />
            </button>
            <button
              className="button extra-small me-2 circle"
              onClick={() => handleEditProject(project)}
            >
              <EditIcon />
            </button>
            <button
              className="button danger extra-small circle"
              onClick={() => handleDelete(project)}
            >
              <DeleteIcon />
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <table className="project-values table table-lg table-striped-custom table-borderless table-header-border">
          <thead>
            <tr>
              <th scope="col">Name</th>
              {/* <th scope="col">Status</th> */}
              <th scope="col">Category</th>
              <th scope="col">UoM</th>
              <th scope="col">Currency</th>
              <th scope="col">Valid From</th>
              <th scope="col">Valid To</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  };

  const getFileUploadErrorRecordsTemplate = (data) => {
    return (
      <div className="mt-2">
        <table className="table table-sm table-striped-custom">
          <thead>
            <tr>
              <th>AreaCode</th>
              <th>ItemCode</th>
              <th>Year</th>
              <th>Month</th>
              <th>Qunatity</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, key) => {
              return (
                <tr key={key}>
                  <td>{row.areaCode}</td>
                  <td>{row.itemCode}</td>
                  <td>{row.year}</td>
                  <td>{row.month}</td>
                  <td>{row.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleShowHelp = () => {
    setShowHelp((showHelp) => {
      return !showHelp;
    });
  };

  const handleShowAuthorizationErrorModel = (e) => {
    setShowAuthorizationErrorModel((showAuthorizationErrorModel) => {
      return !showAuthorizationErrorModel;
    });
    setAuthorizationError(e);
  };

  const getHelp = () => {
    return (
      <div className="animate fadeIn slow col-12">
        <h5>File Uploads</h5>
        <ul>
          <li>
            The project files should be uploaded in CSV (comma separated values)
            format.
          </li>
          <li>
            The CSV file's header must exactly match the Schema. The order of
            the columns must be the same as specified in the Schema.
          </li>
          <li>
            User can edit the file in Excel or in any other application and must
            save it as .CSV file.
          </li>
          <li>
            If any header is missing, a message with missing header name will be
            displayed.
          </li>
          <li>
            Number of rows within the file should not exceed the configured
            threshold value. Threshold value is the total number of rows within
            the file.
          </li>
          <li>
            If the rows are duplicated in the file, a message with duplicated
            records will be displayed.
          </li>
        </ul>
        <h5>Schema</h5>
        <h6>CSV File Header</h6>
        <div className="doc-container">
          <dl>
            <dt>ItemArea Hierarchy</dt>
            <dd>
              <code>AreaCode,ItemCode,Year,Month,Quantity</code>
              <pre>AC1,IC1,2023,9,100</pre>
              <div className="row">
                <div className="col-6">
                  <table className="table table-sm table-striped-custom table-bordered ">
                    <thead>
                      <tr>
                        <th scope="col">AreaCode</th>
                        <th scope="col">ItemCode</th>
                        <th scope="col">Year</th>
                        <th scope="col">Month</th>
                        <th scope="col">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>AC1</td>
                        <td>IC1</td>
                        <td>2023</td>
                        <td>9</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </dd>
          </dl>
        </div>
        <h5>Unit Of Measurements (UoM)</h5>
        <ul>
          <li>UoM works same as manual projects.</li>
          <li>Example item/area record</li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            A file is uploaded with
            <span className="font-weight-medium"> UoM Volume</span> as shown
            below
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>2023</td>
                    <td>9</td>
                    <td>100</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            Project will be saved as
            <span className="font-weight-medium"> 100.</span>
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Project Value</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>100</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            In Search page{" "}
            <span className="font-weight-medium"> UoM Volume </span> will show
            Project as <span className="font-weight-medium"> 100 </span> and{" "}
            <span className="font-weight-medium"> UoM Price </span> will show
            Project as
            <span className="font-weight-medium"> 1000.</span>
          </li>
          <li>
            A file is uploaded with
            <span className="font-weight-medium"> UoM Price</span> as shown
            below
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>2023</td>
                    <td>9</td>
                    <td>200</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            Project will be saved as
            <span className="font-weight-medium"> 20. </span>(This is calculated
            with item/area Price 10)
          </li>
          <div className="row">
            <div className="mt-3 col-6">
              <table className="table table-sm table-striped-custom table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">AreaCode</th>
                    <th scope="col">ItemCode</th>
                    <th scope="col">Project</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AC1</td>
                    <td>IC1</td>
                    <td>20</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <li>
            In Search page{" "}
            <span className="font-weight-medium"> UoM Volume </span> will show
            Project as <span className="font-weight-medium"> 20 </span> and{" "}
            <span className="font-weight-medium"> UoM Price </span> will show
            Project as
            <span className="font-weight-medium"> 200.</span>
          </li>
        </ul>
      </div>
    );
  };

  const isSticky = (e) => {
    const header = document.querySelector(".sticky-header");
    const scrollTop = window.scrollY;

    if (header) {
      scrollTop >= 250
        ? header.classList.add("show")
        : header.classList.remove("show");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error error={error} pathname={projectsPathname} />;
  }

  return (
    <div key="projects" className="ps-2">
      {showAuthorizationErrorModel ? (
        <Modal
          actionType={actionType.Forbidden}
          header={"Forbidden"}
          subHeader={authorizationError?.response?.data?.message}
          handleCancel={handleShowAuthorizationErrorModel}
          setModalOpen={setShowAuthorizationErrorModel}
          showAuthorizationErrorModel={showAuthorizationErrorModel}
        />
      ) : null}
      {showHelp ? (
        <Modal
          actionType={actionType.Help}
          content={getHelp()}
          handleCancel={handleShowHelp}
          setModalOpen={setShowHelp}
        />
      ) : null}
      {showModal ? (
        <Modal
          actionType={actionType.Delete}
          name={currentState.currentProject.name}
          setModalOpen={setShowModal}
          handleCancel={handleCancel}
          handleAction={handleDeleteConfirm}
          actionCallbackValue={currentState.currentProject}
          actionInProgress={isDeleteInProgress}
        />
      ) : (
        ""
      )}
      <div className="row">
        {errorMessages && errorMessages.length > 0 ? (
          <div className="col-12 mt-3">
            {errorMessages.map((e) => {
              return <div className="mb-2 text-danger">{e.value}</div>;
            })}
            {/* <button
              type="button"
              className="button extra-small"
              onClick={() => handleCancel()}
            >
              Start Again
            </button> */}
          </div>
        ) : (
          ""
        )}
      </div>
      {!currentState.currentStep ? (
        <div className="row">
          <div className="col-12 my-3">
            <h4>
              Projects{" "}
              <span role="button" onClick={handleShowHelp}>
                <HelpIcon />
              </span>
            </h4>
          </div>

          <div className="flex col-12">
            {isUserAuthorized(claims.ProjectsWrite) ? (
              <React.Fragment>
                <button className="button" onClick={() => handleAdd()}>
                  <span className="new-icon">
                    <PlusIcon />
                  </span>
                  New
                </button>
              </React.Fragment>
            ) : null}
            {isUserAuthorized(claims.ProjectFileUploadWrite) ? (
              <React.Fragment>
                <button
                  className="mx-2 button"
                  onClick={() => handleAddFileUpload()}
                >
                  <span className="new-icon">
                    <FileUploadIcon />
                  </span>
                  Upload New
                </button>
              </React.Fragment>
            ) : null}
            <div className="col-6 search-bar">
              <span className="search-icon">
                <SearchIcon />
              </span>
              <input
                className="search-input border-radius form-control"
                type="text"
                placeholder="Search by project name"
                value={searchQuery}
                onChange={(e) => handleProjectSearch(e.target.value)}
              />
            </div>
            <button
              className="link clear"
              disabled={!searchQuery}
              onClick={() => handleProjectSearch("")}
            >
              <span className="me-1">
                <ClearCircleIcon />
              </span>
              <span>Clear</span>
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {!currentState.currentStep ? (
        <div className="mt-3">
          {projects && projects.results.length > 0 ? (
            <div>
              {view === viewType.ListView
                ? projects.results.map((project) => (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      currentStep={currentState.currentStep}
                      handleDetailProject={handleDetailProject}
                      handleEditProject={handleEditProject}
                      handleDelete={handleDelete}
                      configuration={configuration}
                      hasWritePermissions={hasWritePermissions}
                      hasDeletePermissions={hasDeletePermissions}
                    ></ProjectCard>
                  ))
                : createProjectsTable()}

              <div className="row py-3">
                <div className="flex middle">
                  <button
                    className="button"
                    onClick={() => handleNext()}
                    disabled={!projects.hasNext}
                  >
                    Load More
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center text-danger m-5">No projects found</div>
          )}
        </div>
      ) : (
        ""
      )}

      {hasWritePermissions() &&
      currentState.currentStep === projectSteps.EditProject ? (
        <EditProject
          currentProject={currentState.currentProject}
          handleEditItemArea={handleEditItemArea}
          handleCancel={handleCancel}
          isFileUpload={currentState.currentProject.isFileUpload}
          handleFileUpload={handleFileUpload}
          validateProjectName={validateProjectName}
          getFileUploadErrorRecordsTemplate={getFileUploadErrorRecordsTemplate}
          maxTimeHorizonInMonths={maxTimeHorizonInMonths}
          configuration={configuration}
          hasWritePermissions={hasWritePermissions}
          fileUploadColumnValueNames={fileUploadColumnValueNames}
        />
      ) : (
        ""
      )}

      {hasWritePermissions() &&
      currentState.currentStep === projectSteps.CreateProject ? (
        <CreateProject
          handleAddItemArea={handleAddItemArea}
          handleCancel={handleCancel}
          isFileUpload={isFileUpload}
          handleFileUpload={handleFileUpload}
          validateProjectName={validateProjectName}
          getFileUploadErrorRecordsTemplate={getFileUploadErrorRecordsTemplate}
          maxTimeHorizonInMonths={maxTimeHorizonInMonths}
          configuration={configuration}
          hasWritePermissions={hasWritePermissions}
          fileUploadColumnValueNames={fileUploadColumnValueNames}
        />
      ) : (
        ""
      )}

      {currentState.currentStep === projectSteps.CreateProjectItemArea ||
      currentState.currentStep === projectSteps.EditProjectItemArea ? (
        <div className="row animate fadeIn slow">
          <div className="col-12">
            <div className="pt-3 project-search">
              <div className="flex">
                <div className="flex">
                  <h4>
                    {currentState.currentProject.name} - Select Item Areas
                  </h4>
                </div>
              </div>
              <div className="flex pt-2">
                <div className="flex">
                  <button
                    type="button"
                    className="link ms-n2"
                    onClick={() => handleBack()}
                  >
                    <span className="arrow-icon back">
                      <BackIcon />
                    </span>
                    <span className="font-weight-medium">Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <Search
              showUom={false}
              showForecast={false}
              setSearchData={setSearchData}
              isWriteOperation={true}
            />
            <div className="form-group pt-3">
              <button
                type="button"
                className="button primary me-2"
                onClick={() => handleSave()}
                disabled={disableSaveButton()}
              >
                Save
              </button>
              <button
                type="button"
                className="button danger"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
            <ItemArea
              selectedSearchData={searchData}
              currentProject={currentState.currentProject}
              setSelectedItemAreaData={setSelectedItemAreaData}
              setAuthorizationError={setAuthorizationError}
              configuration={configuration}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {currentState.currentStep === projectSteps.Details ? (
        <div className="animate fadeIn slow">
          <ProjectDetail
            project={currentState.currentProject}
            currentStep={currentState.currentStep}
            handleDetailProject={handleDetailProject}
            handleEditProject={handleEditProject}
            handleDelete={handleDelete}
            handleCancel={handleCancel}
            configuration={configuration}
            hasWritePermissions={hasWritePermissions}
          ></ProjectDetail>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Project;
